export const KNOWLEDGE_CATEGORIES = {
  ALL: "ALL",
  ORGANIZATION: "ORGANIZATION",
  USER: "USER",
  WORLD: "WORLD",
  SPACE: "SPACE",
  INFERRED: "INFERRED",
  SESSION: "SESSION",
} as const;

export const KNOWLEDGE_CATEGORY_KEYS = Object.freeze(
  Object.keys(KNOWLEDGE_CATEGORIES),
) as KnowledgeCategoryKey[];

export type KnowledgeCategoryKey = keyof typeof KNOWLEDGE_CATEGORIES;

export type KnowledgeCategoryFlags = Record<KnowledgeCategoryKey, boolean>;
export type KnowledgeCategories = KnowledgeCategoryKey[];

export const knowledgeFlagsToCategories = (
  flags: KnowledgeCategoryFlags,
): KnowledgeCategories => {
  return Object.keys(flags).filter(
    (key) => flags[key as KnowledgeCategoryKey],
  ) as KnowledgeCategories;
};

import { type Component, Show } from "solid-js";
import { twMerge } from "tailwind-merge";
import { BUTTONS } from "~/components/common";
import { Icon, type IconProps } from "~/components/icons/Icon";
import type { SimpleButtonProps } from "./types";

export const SimpleButton: Component<Partial<IconProps> & SimpleButtonProps> = (
  props,
) => {
  return (
    <button
      title={props.title}
      type="button"
      onClick={props.onClick}
      class={twMerge(
        BUTTONS.colors,
        BUTTONS.typography,
        BUTTONS.borders,
        BUTTONS.hoverTransition,
        "flex items-center px-4",
        props.class,
      )}
    >
      <Show when={props.iconName}>
        {/* biome-ignore lint/style/noNonNullAssertion: guarded by Show */}
        <Icon name={props.iconName!} size={props.iconSize} />
      </Show>
      <Show when={props.label}>
        <span classList={{ "pl-2": !!props.iconName }}>{props.label}</span>
      </Show>
    </button>
  );
};
